// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const apiUrl = 'https://pm-api-fao8i.ondigitalocean.app';
// const apiUrl ='http://api.prestige-motors.local:8000/',
const apiUrlDev = 'http://192.168.1.131:8000';
const dev = false;


export const environment = {
    production: false,
    paypalClientId: 'AfqNKvCkZ4ExY1NKEB0kwrnqpcX7JfZRC1eNr7787k8WizTyOYIwWYCxLrYry28OlLx6v41bDH1NCtA7',
    autoParts: `${dev ? apiUrlDev : apiUrl}/auto-parts`,
    tecDoc: `${dev ? apiUrlDev : apiUrl}/TecDoc`,
    OneC: `${dev ? apiUrlDev : apiUrl}/integration/1C`,
    apiUrl: `${dev ? apiUrlDev : apiUrl}`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
