<div class="vehicle-select__list" [formGroup]="form">
    <div
        *ngFor="let item of items; let index=index"
        class="vehicle-select__item"
        [class.vehicle-select__item--loading]="item.loading"
    >
<!--        <h1>{{ item.options | json }}</h1>-->

        <select2
            [data]="item.options"
            class="vehicle-select__item-control"
            [attr.aria-label]="item.label|translate"
            [formControlName]="item.key"
            [placeholder]="item.label|translate"
            [listPosition]="'auto'"
            [infiniteScroll]="true"
            [infiniteScrollThrottle]="1"
            [styleMode]="'default'"
        >

<!--            <ng-template let-label="label" #template>-->
<!--                <span class="vehicle-select__item-option"-->
<!--                      [style.cursor]="'pointer'"><span>{{label}}</span></span></ng-template>-->
            <!--            <option value="none">{{ item.placeholder|translate }}</option>-->
            <!--            <option *ngFor="let option of item.options; let isLast = last; let isOdd = odd" [value]="serializeOption(option.id, item)">-->
            <!--                <span>{{ serializeOption(option.name, item) }}</span>-->
            <!--                <ng-container *ngIf="isLast && isOdd">-->
            <!--                    <span [style.cursor]="'pointer'" (click)="onScroll($event)">Load More</span>-->
            <!--                </ng-container>-->
            <!--            </option>-->
        </select2>
        <div class="vehicle-select__item-loader"></div>
    </div>
</div>
