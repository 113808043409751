<div class="container">
    <div class="block-header__body">
        <app-breadcrumb
            *ngIf="breadcrumb?.length"
            class="block-header__breadcrumb"
            [items]="breadcrumb"
            [withPageTitle]="!!pageTitle"
            [afterHeader]="afterHeader"
        >
        </app-breadcrumb>
        <h1 *ngIf="pageTitle" class="block-header__title" #titleElement>{{ pageTitle }}</h1>
    </div>
</div>
