import { roundToNearestInRange } from '../utils';

export function fitImageCalculation(logos: any, MAX_WIDTH: number) {
    const urls = Object.values(logos);
    const calcRatio = MAX_WIDTH / window.innerWidth;
    return urls[roundToNearestInRange(calcRatio, 0, urls.length - 1)];
}

export function fitImageCalcForAllImages(diffImagesOfSameProduct: any[]) {
    const collectedImageUrls = [];
    let correctSizeIndex = null;
    function calculateValueInRange(ratio, min, max) {
        const scaledValue = min + (max - min) * ratio;
        const roundedValue = Math.round(scaledValue);
        return Math.min(Math.max(min, roundedValue), max);
    }
    diffImagesOfSameProduct.forEach((diffImgRelObj, index: number) => {
        const onlyImageIncludedEntries = Object
            .entries(diffImgRelObj)
            .filter((imgKeyVal) => isUIImageCheck(imgKeyVal));
        if (index === 0 && !!onlyImageIncludedEntries.length) {
            const urls = onlyImageIncludedEntries
                .reduce(
                    (prevEntries, currEntry) => [...prevEntries, currEntry[1]],
                    [],
                );
            const calcRatio = 1920 / window.innerWidth;
            correctSizeIndex = calculateValueInRange(calcRatio, 0, urls.length - 1);
        }
        collectedImageUrls.push(onlyImageIncludedEntries[correctSizeIndex][1]);
    });
    return collectedImageUrls.map(imgUrl => ({
        imageURL: imgUrl,
    }));
}

export function isUIImageCheck(imgKeyVal: any): boolean {
    return imgKeyVal[0].includes('image') ;
}
