import { Address } from '../../app/interfaces/address';
import { tap } from 'rxjs/operators';

let lastId = 0;

export function getId(): number {
    return ++lastId;
}


export const addresses: Address[] = [];
