import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Vehicle } from '../../interfaces/vehicle';
import { delay, map, pluck, retryWhen, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { addUserVehicles, getUserVehicles, getYears, removeUserVehicles } from '../../../fake-server/endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../modules/language/services/language.service';


@Injectable()
export class VehicleApiNew implements OnDestroy {
    currentLanguage: string;
    private reloadUserVehicles: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    private destroy$: Subject<void> = new Subject<void>();
    private currentVehicleSubject: BehaviorSubject<Vehicle> = new BehaviorSubject<Vehicle>(null);

    userVehicles$: Observable<Vehicle[]> = this.reloadUserVehicles.pipe(
        switchMap(() => getUserVehicles()),
        shareReplay(1),
    );

    currentVehicle$: Observable<Vehicle> = this.currentVehicleSubject.pipe(
        switchMap(currentVehicle => this.userVehicles$.pipe(
            map(vehicles => vehicles.find(x => currentVehicle && x.id === currentVehicle.id) || null),
        )),
    );

    constructor(private http: HttpClient, private languageService: LanguageService) {
        this.languageService.current$.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
            this.currentLanguage = lang.code;
        });
    }

    getMakes(): Observable<any[]> {
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                getLinkageTargets: {
                    provider: 23629,
                    articleCountry: 'ru',
                    linkageTargetCountry: 'ru',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    linkageTargetType: 'V',
                    perPage: 0,
                    page: 1,
                    includeMfrFacets: true,
                },
            },
        }).pipe(
            pluck('data', 'mfrFacets', 'counts'),
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
    }

    getSeries(mfrIds: number): Observable<any[]> {
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                getLinkageTargets: {
                    provider: 23629,
                    linkageTargetCountry: 'ru',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    linkageTargetType: 'V',
                    perPage: 0,
                    page: 1,
                    mfrIds,
                    includeVehicleModelSeriesFacets: true,
                },
            },
            get: 'model_series_more',
        }).pipe(
            pluck('data', 'vehicleModelSeriesFacets', 'counts'),
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
    }

    getYears(mfrIds: number, modelSeriesIds?: string): Observable<any[]> {
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                getLinkageTargets: {
                    provider: 23629,
                    linkageTargetCountry: 'ru',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    linkageTargetType: 'V',
                    perPage: 0,
                    page: 1,
                    includeYearFacets: true,
                    mfrIds,
                    vehicleModelSeriesIds: modelSeriesIds?.split(',').map(Number),
                },
            },
        }).pipe(
            pluck('data', 'yearFacets', 'counts'),
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
    }

    getBody(mfrIds: number, modelSeriesIds?: string, years?: number): Observable<any[]> {
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                getLinkageTargets: {
                    provider: 23629,
                    linkageTargetCountry: 'ru',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    linkageTargetType: 'V',
                    perPage: 100,
                    page: 1,
                    includeBodyFacets: true,
                    mfrIds,
                    vehicleModelSeriesIds: modelSeriesIds?.split(',').map(Number),
                    years,
                },
            },
            get: 'model_series_body',
        }).pipe(
            tap(console.log),
            pluck('data', 'bodyTypesFacets'),
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
    }

    getEngines(item: any, modelSeriesIds: string, year: string, bodyStyleKeys: string) {
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                getLinkageTargets: {
                    provider: 23629,
                    linkageTargetCountry: 'ru',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    linkageTargetType: 'V',
                    perPage: 100,
                    page: 1,
                    includeEngineFacets: true,
                    mfrIds: item,
                    vehicleModelSeriesIds: modelSeriesIds.split(',').map(Number),
                    years: year,
                    bodyStyleKeys,
                },
            },
            get: 'model_series_engine',
        }).pipe(
            pluck('data', 'engineFacets'),
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
    }

    getModifications(item: string, modelSeriesIds: string, year: string, bodyStyleKeys: string, engine: string) {
        const [capacityLiters, fuelTypeKey] = engine.split(' ').map(Number);
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                getLinkageTargets: {
                    provider: 23629,
                    linkageTargetCountry: 'ru',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    linkageTargetType: 'V',
                    perPage: 100,
                    page: 1,
                    includeEngineFacets: true,
                    mfrIds: item,
                    vehicleModelSeriesIds: modelSeriesIds?.split(',').map(Number),
                    years: year,
                    includeAll: true,
                    bodyStyleKeys,
                    capacityLiters,
                    fuelTypeKey,
                },
            },
            get: 'getLinkageTargets',
        }).pipe(
            pluck('data', 'linkageTargets'),
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
    }

    addUserVehicle(vehicleId: number): Observable<void> {
        return addUserVehicles(vehicleId).pipe(
            tap(() => this.reloadUserVehicles.next()),
        );
    }

    removeUserVehicle(vehicleId: number): Observable<void> {
        return removeUserVehicles(vehicleId).pipe(
            tap(() => this.reloadUserVehicles.next()),
        );
    }

    setCurrentVehicle(vehicle: Vehicle): void {
        this.currentVehicleSubject.next(vehicle);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
