import { Injectable, OnDestroy } from '@angular/core';
import { VehicleApi } from '../base';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Vehicle } from '../../interfaces/vehicle';
import { delay, map, pluck, retryWhen, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { addUserVehicles, getUserVehicles, getYears, removeUserVehicles } from '../../../fake-server/endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../modules/language/services/language.service';


@Injectable()
export class FakeVehicleApi extends VehicleApi implements OnDestroy {
    currentLanguage: string;
    private reloadUserVehicles: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    private destroy$: Subject<void> = new Subject<void>();
    private currentVehicleSubject: BehaviorSubject<Vehicle> = new BehaviorSubject<Vehicle>(null);

    userVehicles$: Observable<Vehicle[]> = this.reloadUserVehicles.pipe(
        switchMap(() => getUserVehicles()),
        shareReplay(1),
    );

    currentVehicle$: Observable<Vehicle> = this.currentVehicleSubject.pipe(
        switchMap(currentVehicle => this.userVehicles$.pipe(
            map(vehicles => vehicles.find(x => currentVehicle && x.id === currentVehicle.id) || null),
        )),
    );

    constructor(private http: HttpClient, private languageService: LanguageService) {
        super();
        this.languageService.current$.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
            this.currentLanguage = lang.code;
        });
    }

    getYears(): Observable<string[]> {
        return getYears();
    }

    getMakes(years: number): Observable<any[]> {
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                getLinkageTargets: {
                    provider: 23629,
                    articleCountry: 'am',
                    linkageTargetCountry: 'am',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    linkageTargetType: 'P',
                    perPage: 0,
                    page: 1,
                    includeMfrFacets: true,
                    years,
                },
            },
        }).pipe(
            pluck('data', 'mfrFacets', 'counts'),
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
    }

    getModels(years: number, mfrId: number, page: number = 1, perPage: number = 100): Observable<any[]> {
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                getLinkageTargets: {
                    provider: 23629,
                    articleCountry: 'am',
                    linkageTargetCountry: 'am',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    mfrIds: Number(mfrId),
                    linkageTargetType: 'P',
                    includeVehicleModelSeriesFacets: true,
                    perPage: 0,
                    page,
                    years,
                },
            },
        }).pipe(
            pluck('data', 'vehicleModelSeriesFacets', 'counts'),
            // map(models => {
            //     const ids = new Set();
            //     return models.filter(model => {
            //         if (!ids.has(model.vehicleModelSeriesId)) {
            //             ids.add(model.vehicleModelSeriesId);
            //             return true;
            //         }
            //         return false;
            //     });
            // }),
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
    }

    getVehicles(years: number, brand: number, vehicleModelSeriesId: number): Observable<any[]> {
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                // getVehiclesByID or getVehiclesByCriteria
                // getVehicleIdsByCriteria: {
                getLinkageTargets: {
                    provider: 23629,
                    articleCountry: 'am',
                    linkageTargetCountry: 'am',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    linkageTargetType: 'P',
                    vehicleModelSeriesIds: Number(vehicleModelSeriesId),
                    mfrIds: brand,
                    // carType: 'P',
                    // modId: Number(vehicleModelSeriesId),
                    // countriesCarSelection: 'am',
                    years,
                    perPage: 100,
                    page: 1,
                    includeMfrFacets: true,
                },
            },
        }).pipe(
            // pluck('data', 'mfrFacets', 'counts'),
            pluck('data', 'linkageTargets'),
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
    }

    getVehicleByVin(vin: string): Observable<Vehicle> {
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                getVehiclesByVIN: {
                    provider: 23629,
                    country: 'am',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    vin,
                },
            },
            // return getVehicleByVin(vin);
        }).pipe(
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
    }

    addUserVehicle(vehicleId: number): Observable<void> {
        return addUserVehicles(vehicleId).pipe(
            tap(() => this.reloadUserVehicles.next()),
        );
    }

    removeUserVehicle(vehicleId: number): Observable<void> {
        return removeUserVehicles(vehicleId).pipe(
            tap(() => this.reloadUserVehicles.next()),
        );
    }

    setCurrentVehicle(vehicle: Vehicle): void {
        this.currentVehicleSubject.next(vehicle);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
