import { Observable } from 'rxjs';
import { Vehicle } from '../../interfaces/vehicle';

export abstract class VehicleApi {
    abstract userVehicles$: Observable<Vehicle[]>;

    abstract currentVehicle$: Observable<Vehicle>;

    abstract getYears(): Observable<string[]>;

    abstract getMakes(years: number): Observable<any[]>;

    abstract getModels(years: number, mfrId: number, page: number, perPage: number): Observable<any[]>;

    // abstract getVehicles(make: string, model: string): Observable<Vehicle[]>;
    abstract getVehicles(year: number, mfrId: number, vehicleModelSeriesIds: number): Observable<any[]>;

    abstract getVehicleByVin(vin: string): Observable<Vehicle>;

    abstract addUserVehicle(vehicleId: number): Observable<void>;

    abstract removeUserVehicle(vehicleId: number): Observable<void>;

    abstract setCurrentVehicle(vehicle: Vehicle): void;
}
