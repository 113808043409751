<span class="input-check__body">
    <input
        class="input-check__input"
        type="checkbox"
        [disabled]="disabled"
        [checked]="checked"
        [id]="inputId"
        (change)="onInputChange($event)"
    >
    <span class="input-check__box"></span>
    <span class="input-check__icon">
        <svg width="9px" height="7px">
            <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"/>
        </svg>
    </span>
</span>
