import { ShopCategory } from '../../app/interfaces/category';
import { Product } from '../../app/interfaces/product';
import { CategoryFilter } from '../../app/interfaces/filter';
import { AbstractFilterBuilder } from './abstract-filter-builder';
import { CategoryService } from '../../app/services/category.service';

export class CategoryFilterBuilder extends AbstractFilterBuilder {

    constructor(public slug: string, public name: string, readonly categoryService?: CategoryService) {
        super('category', 'Categories');
    }

    private value: string = null;

    private items: ShopCategory[] = [];

    test(product: Product): boolean {
        return true;
    }

    makeItems(products: Product[], value: string): void {
        this.value = value;

        const category = this.categoryService.shopCategoriesList.getValue().find(x => x.slug === value);
        console.log('category', category);
        if (category) {
            this.items = [this.categoryService.prepareCategory(category, 1)];
        } else {
            this.items = this.categoryService.shopCategoriesTree.getValue().map(x => this.categoryService.prepareCategory(x));
        }
    }

    calc(filters: AbstractFilterBuilder[]): void {

    }

    build(): CategoryFilter {
        return {
            type: 'category',
            slug: this.slug,
            name: this.name,
            items: this.items,
            value: this.value,
        };
    }
}
