import { BehaviorSubject, Observable } from 'rxjs';
import { ShopCategory, TecDocCategory } from '../../interfaces/category';
import { Product } from '../../interfaces/product';
import { Brand } from '../../interfaces/brand';
import { ProductsList, ReviewsList } from '../../interfaces/list';
import { Review } from '../../interfaces/review';
import { Order } from '../../interfaces/order';
import { AddressData } from '../../interfaces/address';
import { Vehicle } from '../../interfaces/vehicle';
import { SortTypes } from '../../modules/shared/interfaces/Types';

export interface GetCategoryBySlugOptions {
    depth?: number;
}

export interface GetCategoriesOptions {
    parent?: Partial<ShopCategory>;
    slugs?: string[];
    depth?: number;
}

export interface GetBrandsOptions {
    limit?: number;
}

export interface GetProductsListOptions {
    page?: number;
    perPage?: number;
    sort?: SortTypes;
    filters?: { [slug: string]: string };
}

export interface GetProductReviewsOptions {
    page?: number;
    perPage?: number;
    sort?: SortTypes;
    filters?: { [slug: string]: string };
}

export interface AddProductReviewData {
    rating: number;
    author: string;
    email: string;
    content: string;
}

export interface GetSearchSuggestionsOptions {
    limitProducts?: number;
    limitCategories?: number;
}

export interface GetSearchSuggestionsResult {
    products: Product[];
    categories: ShopCategory[];
}

export interface CheckoutItemOptionData {
    name: string;
    value: string;
}

export interface CheckoutItemData {
    productId: string;
    productBrand: any;
    options?: CheckoutItemOptionData[];
    quantity: number;
}

export interface CheckoutData {
    payment: string;
    items: CheckoutItemData[];
    billingAddress?: AddressData;
    shippingAddress: AddressData;
    comment: string;
}

export const GenericCheckoutData = {
    data: {
        payment: 'cash',
        items: [],
        shippingAddress: {
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postcode: '',
            email: '',
            phone: '',
        },
        comment: '',
    },
};

export abstract class ShopApi {

    abstract getVehicleByVIN(vin: string): Observable<any>;

    abstract getCategoryBySlug(slug: string, shopCategoriesList: ShopCategory[], options?: GetCategoryBySlugOptions): Observable<ShopCategory>;

    abstract getCategories(shopCategoriesTree: ShopCategory[], shopCategoriesList: ShopCategory[], options?: GetCategoriesOptions): Observable<ShopCategory[]>;

    abstract getCategoriesFromTecDoc(mfrId?: number, vehicleModelSeriesId?: number, linkageTargetId?: number): Observable<TecDocCategory[]>;

    abstract getCategoriesFromTecDocByBrand(dataSupplierIds?: string): Observable<TecDocCategory[]>;

    abstract getCategoriesCarFilter(mfrId: number, vehicleModelSeriesId: number, linkageTargetId: number): Observable<TecDocCategory[]>;

    abstract getCategoriesBrandFilter(filterBrand: any): Observable<TecDocCategory[]>;

    abstract getBrands(options?: GetBrandsOptions): Observable<Brand[]>;

    abstract getProductsListByQuery(query: string, dataSupplierIds?: string, options?: GetProductsListOptions): Observable<any>;

    abstract getProductsListByAuto(options?: GetProductsListOptions, categoryFilter?: string, mfrId?: string, vehicleModelSeriesId?: string, linkageTargetId?: string): Observable<ProductsList>;

    abstract getProductsListByBrands(options?: GetProductsListOptions, categoryFilter?: string, brandFilter?: string): Observable<ProductsList>;

    abstract getProductBySlug(slug: string): Observable<Product>;

    abstract getProductReviews(productId: number, options?: GetProductReviewsOptions): Observable<ReviewsList>;

    abstract addProductReview(productId: number, data: AddProductReviewData): Observable<Review>;

    abstract getProductAnalogs(productId: number): Observable<Product[]>;

    abstract getRelatedProducts(productId: number, limit: number): Observable<Product[]>;

    abstract getFeaturedProducts(categorySlug: string, limit: number): Observable<Product[]>;

    abstract getPopularProducts(categorySlug: string, limit: number): Observable<Product[]>;

    abstract getTopRatedProducts(categorySlug: string, limit: number): Observable<Product[]>;

    abstract getSpecialOffers(limit: number): Observable<Product[]>;

    abstract getLatestProducts(limit: number): Observable<Product[]>;

    // abstract getSearchSuggestions(query: string, options?: GetSearchSuggestionsOptions): Observable<GetSearchSuggestionsResult>;
    abstract getSearchSuggestions(query: { searchText: string, productShowCount: number }): Observable<any>;

    abstract checkout(data: CheckoutData): Observable<Order>;

    abstract getAccessoryList(articleCountry: string, articleId: number): Observable<any>;

    abstract getPartList(articleCountry: string, articleId: number): Observable<any>;

}
