import { Injectable } from '@angular/core';
import { AccountApi, EditAddressData, EditProfileData, GetOrdersListOptions } from '../base';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { User } from '../../interfaces/user';
import { delay, map, pluck, retryWhen, take, tap } from 'rxjs/operators';
import { Address, AddressData } from '../../interfaces/address';
import { OrdersList } from '../../interfaces/list';
import { Order, OrderItem, OrderItemOption, OrderTotal } from '../../interfaces/order';
import {
    accountChangePassword,
    accountEditProfile,
    accountSignOut,
    accountSignUp,
    addAddress,
    delAddress,
    editAddress,
    getAddress,
    getAddresses,
    getDefaultAddress,
    getOrderById,
    getOrderByToken,
    getOrdersList,
} from '../../../fake-server/endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Product } from '../../interfaces/product';


@Injectable()
export class FakeAccountApi extends AccountApi {
    private userSubject: BehaviorSubject<User | null>;

    get user(): User | null {
        return this.userSubject.value;
    }

    readonly user$: Observable<User | null>;

    readonly orders$: BehaviorSubject<Order[] | null> = new BehaviorSubject<Order[] | null>(null);

    constructor(private http: HttpClient) {
        super();

        const storedUser = localStorage.getItem('user');

        this.userSubject = new BehaviorSubject<User | null>(storedUser ? JSON.parse(storedUser) : null);
        this.user$ = this.userSubject.asObservable();
    }

    signIn(email: string, password: string): Observable<any> {
        return this.http.post<any>(`${environment.OneC}/login`, {Email: email, Password: password}).pipe(
            tap((response) => {
                if (response.message === 'Login Successful') {
                    const user: User = {
                        ...this.user,
                        email: response.data?.email,
                        avatar: response.data?.avatar,
                        phone: response.data?.phone,
                        lastName: response.data?.lastName,
                        firstName: response.data?.firstName,
                    };
                    this.userSubject.next(user);
                    this.addAddress({
                        firstName: response?.data?.firstName,
                        lastName: response?.data?.lastName,
                        company: 'Prestige Motors',
                        country: 'AM',
                        address1: '127, 21 Arshakunyats Ave',
                        address2: '',
                        city: 'Yerevan',
                        state: 'Yerevan',
                        postcode: '0015',
                        email: response?.data?.email ?? 'Example@prestigemotors.llc',
                        phone: response?.data?.phone ?? '999999999999',
                        default: true,
                    });
                    return this.setUser(user);
                } else {
                    alert('wrong credentials');
                    throwError('login error');
                }
            }),
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
    }


    signUp(email: string, password: string): Observable<User> {
        return accountSignUp(email, password).pipe(
            tap(user => this.setUser(user)),
        );
    }

    signOut(): Observable<void> {
        return accountSignOut().pipe(
            tap(() => this.setUser(null)),
        );
    }

    editProfile(data: EditProfileData): Observable<User> {
        return accountEditProfile(data).pipe(
            tap(user => this.setUser(user)),
        );
    }

    changePassword(oldPassword: string, newPassword: string): Observable<void> {
        return accountChangePassword(oldPassword, newPassword);
    }

    addAddress(data: EditAddressData): Observable<Address> {
        return addAddress(data);
    }

    editAddress(addressId: number, data: EditAddressData): Observable<Address> {
        return editAddress(addressId, data);
    }

    delAddress(addressId: number): Observable<void> {
        return delAddress(addressId);
    }

    getDefaultAddress(): Observable<Address | null> {
        return getDefaultAddress();
    }

    getAddress(addressId: number): Observable<Address> {
        return getAddress(addressId);
    }

    getAddresses(): Observable<Address[]> {
        return getAddresses();
    }

    getOrdersList(options?: GetOrdersListOptions): Observable<OrdersList> {
        return this.http.post(`${environment.OneC}/getOrder`, {
            data: {
                email: this.user?.email,
            },
        }).pipe(
            map((response: any) => {
                const orders = response.data.reverse().map((order: any, index: number) => {
                    return {
                        id: response.data.length - index,
                        token: order.Code_1C_Order,
                        number: (response.data.length - index).toString(),
                        createdAt: order.created_at,
                        payment: order.Payment_Method,
                        status: order?.Status,
                        items: order.items.map((item: any) => {
                            return {
                                product: {name: item.Product_Name} as unknown as Product,
                                options: [item.Product_Name, item.Brand_Name],
                                price: item.Price,
                                quantity: item.Quantity,
                                total: item.Quantity * item.Price,
                            } as OrderItem;
                        }),
                        quantity: order.Quantity,
                        subtotal: order.Cost,
                        totals: order.items.map((item: any) => {
                            return {
                                title: item.Product_Name,
                                price: item.Price,
                            } as OrderTotal;
                        }),
                        total: order.Cost,
                        shippingAddress: '',
                        billingAddress: '',
                    } as unknown as Order;
                });
                console.log(orders, 'orders');
                this.orders$.next(orders);
                return {items: orders, ...options} as OrdersList;
            }));
        // return getOrdersList(options);
    }

    getOrderById(id: number): Observable<Order> {
        return of(this.orders$.getValue()?.find(order => order.id === id) ?? null);
    }

    getOrderByToken(token: string): Observable<Order> {
        return getOrderByToken(token);
    }

    private setUser(user: User): void {
        this.userSubject.next(user);

        localStorage.setItem('user', JSON.stringify(user));
    }
}
