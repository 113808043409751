import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appIsMobileView]',
})
export class IsMobileViewDirective implements OnInit, OnDestroy {
    @Input() appIsMobileView = false; // Optional input to allow setting from outside
    @Output() isMobileView: EventEmitter<boolean> = new EventEmitter<boolean>(); // Optional input to allow setting from outside

    private isMobile = false;

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    @HostListener('window:resize') onResize = () => {
        this.checkMobileView();
    };

    ngOnInit() {
        this.checkMobileView();
    }

    ngOnDestroy() {
        // Optional cleanup if needed
    }

    private checkMobileView() {
        const isMobile = window.innerWidth <= 1200;

        if (isMobile !== this.isMobile) {
            this.isMobile = isMobile;
            this.isMobileView.emit(this.isMobile);
        }
    }
}
