<a routerLink="/" class="logo">
<!--    <div class="logo__slogan">-->
<!--        {{ 'TEXT_SLOGAN'|translate }}-->
<!--    </div>-->
    <div class="logo__image">
        <!-- logo -->
        <img  src="assets/images/logo.svg" alt="Prestige Motors LLC">
        <!-- logo / end -->
    </div>

</a>
<div class="search__decor">
    <div class="search__decor-start"></div>
    <div class="search__decor-end"></div>
</div>
