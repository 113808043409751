export const theme = {
    name: 'PrestigeMotors',
    url: 'https://pgm.am',
    author: {
        name: 'MasterMind',
        profile_url: 'https://www.google.com/search?q=MastermindLLC&rlz=1C5CHFA_enAM1082AM1082&oq=MastermindLLC&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQABgNGIAEMgoIAhAAGAgYDRgeMgoIAxAAGAgYDRgeMgoIBBAAGAgYDRgeMgoIBRAAGAgYDRgeMgoIBhAAGAgYDRgeMgoIBxAAGAgYDRgeMgoICBAAGAgYDRgeMgoICRAAGAgYDRge0gEINTQ3NGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8',
    },
    contacts: {
        address: ['ADDRESS_ARSHAKUNYATS'],
        email: ['info@pgm.am'],
        phone: ['+374 (010) 48-41-00'],
        hours: ['MON_SAT'],
    },
};
