// import { GetBlogCategoriesOptions, GetCategoriesOptions, GetCategoryBySlugOptions } from '../../app/api';
// import { Observable, of, throwError } from 'rxjs';
// import { BaseCategory, BlogCategory, ShopCategory } from '../../app/interfaces/category';
// import { blogCategoriesTree, shopCategoriesList, shopCategoriesTree } from '../database/categories';
import { blogCategoriesTree } from '../database/categories';
import { HttpErrorResponse } from '@angular/common/http';
import { clone } from '../utils';



// export function getCategoryBySlug(slug: string, shopCategoriesList, options?: GetCategoryBySlugOptions): Observable<ShopCategory> {
//     options = options || {};
//
//     const category = shopCategoriesList.find(x => x.slug === slug);
//
//     if (!category) {
//         return throwError(new HttpErrorResponse({status: 404, statusText: 'Page Not Found'}));
//     }
//
//     return of(prepareCategory(category, options.depth));
// }

// export function getCategories(shopCategoriesTree, shopCategoriesList, options?: GetCategoriesOptions): Observable<ShopCategory[]> {
//     let categories = shopCategoriesTree.slice(0);
//     const depth = options.depth || 0;
//
//     if (options.parent) {
//         const parent = shopCategoriesList.find(x => x.slug === options.parent.slug);
//
//         if (parent) {
//             categories = parent.children;
//         }
//     } else if (options.slugs) {
//         categories = shopCategoriesList.filter(x => options.slugs.includes(x.slug));
//     }
//
//     categories = categories.map(x => prepareCategory(x, depth));
//
//     return of(clone(categories));
// }

// export function getBlogCategories(options: GetBlogCategoriesOptions): Observable<BlogCategory[]> {
//     let categories = blogCategoriesTree.slice(0);
//     const depth = options.depth || 0;
//
//     categories = categories.map(x => prepareCategory(x, depth));
//
//     return of(clone(categories));
// }
