import { Injectable } from '@angular/core';
import { BlogApi, GetBlogCategoriesOptions } from '../base';
import { Observable, of } from 'rxjs';
import { Post } from '../../interfaces/post';
import { getLatestPosts } from '../../../fake-server/endpoints';
import { BlogCategory } from '../../interfaces/category';
import { CategoryService } from '../../services/category.service';

@Injectable()
export class FakeBlogApi extends BlogApi {


    constructor() {
        super();
    }

    getLatestPosts(limit: number): Observable<Post[]> {
        return getLatestPosts(limit);
    }

    getCategories(options: GetBlogCategoriesOptions): Observable<BlogCategory[]> {
        return of(null);
        // return this.categoryService.getBlogCategories(options);
    }
}
