import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    NgZone,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AccountApi, ShopApi, VehicleApi } from '../../../../api';
import { fromEvent, Observable, Subject } from 'rxjs';
import { Vehicle } from '../../../../interfaces/vehicle';
import { catchError, debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Product } from '../../../../interfaces/product';
import { ShopCategory } from '../../../../interfaces/category';
import { UrlService } from '../../../../services/url.service';
import { isPlatformBrowser } from '@angular/common';
import { fromOutsideClick } from '../../../../functions/rxjs/from-outside-click';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { fitImageCalcForAllImages } from '../../../../functions/helpers/image-helper.service';
import { nameToSlug } from '../../../../../fake-server/utils';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
    private destroy$: Subject<void> = new Subject<void>();

    query$: Subject<string> = new Subject<string>();

    suggestionsIsOpen = false;

    hasSuggestions = false;

    searchPlaceholder$: Observable<string>;

    vehiclePickerIsOpen = false;

    vehiclePanel: 'list' | 'form' = 'list';

    vehicles$: Observable<Vehicle[]>;

    currentVehicle$: Observable<Vehicle>;

    currentVehicleControl: FormControl = new FormControl(null);

    addVehicleControl: FormControl = new FormControl(null);

    products: Product[] = [];

    categories: ShopCategory[] = [];

    isAuth$: Observable<boolean>;

    form: FormGroup;

    @HostBinding('class.search') classSearch = true;

    @ViewChild('selectVehicleButton') selectVehicleButton: ElementRef<HTMLElement>;
    @ViewChild('siteSearch') siteSearch: ElementRef<HTMLElement>;
    @ViewChild('vehiclePickerDropdown') vehiclePickerDropdown: ElementRef<HTMLElement>;

    // TODO add search autofocus
    // @HostListener('window:keydown', ['$event'])
    // handleKeyDown(event: KeyboardEvent): void {
    //     const key = event.key;
    //     const regex = /^[a-zA-Z0-9]$/;
    //
    //     if (regex.test(key)) {
    //         this.siteSearch.nativeElement.focus();
    //     }
    // }

    get element(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private zone: NgZone,
        private vehiclesApi: VehicleApi,
        private shopApi: ShopApi,
        private translate: TranslateService,
        private elementRef: ElementRef,
        public url: UrlService,
        private router: Router,
        private account: AccountApi,
    ) {
        this.isAuth$ = this.account.user$.pipe(map(x => x !== null));
        this.form = new FormGroup({
            siteSearch: new FormControl(''),
        });
        this.form.get('siteSearch').valueChanges.pipe(
            debounceTime(300),
            filter((value) => value !== undefined),
            filter(value => value?.length !== 0),
        ).subscribe(value => {
            this.search(value);
        });
    }

    ngOnInit(): void {
        this.vehicles$ = this.vehiclesApi.userVehicles$;
        this.currentVehicle$ = this.vehiclesApi.currentVehicle$;
        // this.currentVehicleControl.valueChanges.pipe(
        //     switchMap(vehicleId => this.vehiclesApi.userVehicles$.pipe(
        //         filter((vehicles) => vehicles !== undefined),
        //         map(vehicles => vehicles.find(x => x.id === vehicleId) || null)),
        //     ),
        // ).subscribe(vehicle => this.vehiclesApi.setCurrentVehicle(vehicle));

        this.currentVehicle$.subscribe(vehicle => this.currentVehicleControl.setValue(vehicle ? vehicle.id : null, {emitEvent: false}));

        this.query$.pipe(
            debounceTime(2000),
            switchMap(searchText => {
                return this.shopApi.getSearchSuggestions({searchText, productShowCount: 6});
            }),
            catchError((err, caught) => {
                return caught;
                // return [];
            }),
        ).subscribe(result => {
            const articles = result.data?.articles;
            if (articles?.length === 0 || !articles) { // && result.categories.length === 0
                this.hasSuggestions = false;
                return;
            }

            this.products = articles.map(
                (article: any) => ({
                    id: article.genericArticles[0]?.genericArticleId,
                    name: article.genericArticles[0]?.genericArticleDescription,
                    slug: nameToSlug(article.genericArticles[0]?.genericArticleDescription),
                    mfrName: article.mfrName,
                    dataSupplierIds: article.dataSupplierId,
                    images: article.images.map(image => {
                        return image.imageURL1600;
                    }),
                    brand: {
                        name: article.mfrName,
                    },
                    sku: article?.articleNumber,
                    oemNumbers: article.oemNumbers,
                    price: article?.prices[0] ?? article?.prices[0] ?? 0,
                    stock: Number.isInteger(article?.prices) ?
                        article?.prices === 0 ? 'out-of-stock' : 'in-stock'
                        : article.length === 0 ? 'out-of-stock' : 'in-stock',
                    status: article?.misc?.articleStatusDescription,
                    description: article?.articleText[0]?.text ?? 'No Description',
                    productImages: fitImageCalcForAllImages(article.images),
                    specification: article?.articleCriteria
                        ?.map(criteria => ({
                                desc: criteria?.criteriaDescription,
                                value: criteria?.rawValue,
                                unit: criteria?.criteriaUnitDescription,
                            }),
                        ),
                } as unknown as Product),
            );
            this.hasSuggestions = true;

            // this.categories = result.categories;
        });

        this.searchPlaceholder$ = this.vehiclesApi.currentVehicle$.pipe(
            switchMap(vehicle => {
                if (vehicle) {
                    return this.translate.stream('INPUT_SEARCH_PLACEHOLDER_VEHICLE', vehicle);
                }

                return this.translate.stream('INPUT_SEARCH_PLACEHOLDER');
            }),
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        this.zone.runOutsideAngular(() => {
            fromOutsideClick([
                this.selectVehicleButton.nativeElement,
                this.vehiclePickerDropdown.nativeElement,
            ]).pipe(
                filter(() => this.vehiclePickerIsOpen),
                takeUntil(this.destroy$),
            ).subscribe(() => {
                this.zone.run(() => this.vehiclePickerIsOpen = false);
            });

            fromOutsideClick(this.element).pipe(
                filter(() => this.suggestionsIsOpen),
                takeUntil(this.destroy$),
            ).subscribe(() => {
                this.zone.run(() => this.toggleSuggestions(false));
            });

            fromEvent(this.element, 'focusout').pipe(
                debounceTime(10),
                takeUntil(this.destroy$),
            ).subscribe(() => {
                if (document.activeElement === document.body) {
                    return;
                }

                // Close suggestions if the focus received an external element.
                if (document.activeElement && document.activeElement.closest('.search') !== this.element) {
                    this.zone.run(() => this.toggleSuggestions(false));
                }
            });
        });
    }

    search(query: string): void {
        this.query$.next(query);
    }

    toggleSuggestions(force?: boolean, url?: string, query?: string, dataSupplierIds?: string): void {
        this.suggestionsIsOpen = force !== undefined ? force : !this.suggestionsIsOpen;
        if (url) {
            this.router.navigate(['loading']).then(() => this.router.navigate([url], {
                queryParams: {
                    query,
                    dataSupplierIds,
                    perPage: 8,
                    page: 1,
                    sort: 'default',
                },
                queryParamsHandling: 'merge',
                replaceUrl: true,
            }));
        }
        if (this.suggestionsIsOpen) {
            this.toggleVehiclePicker(false);
        }

    }

    toggleVehiclePicker(force?: boolean): void {
        // TODO remove this return statement when the vehicle picker is implemented
        return;
        this.vehiclePickerIsOpen = force !== undefined ? force : !this.vehiclePickerIsOpen;

        // if (this.vehiclePickerIsOpen) {
        //     this.toggleSuggestions(false);
        // }
    }

    onInputFocus(event: FocusEvent): void {
        const input = event.target as HTMLInputElement;
        this.toggleSuggestions(true);
        if (input.value !== '' && input.value !== undefined) {
            this.search(input.value);
        }
    }

    isSearchDisabled(): boolean {
        return this.form.get('siteSearch').value === '' || this.form.get('siteSearch').value.length < 3;
    }

    submit(): void {
        // TODO modify
        console.log('this.form.get(\'siteSearch\').value', this.form.get('siteSearch').value);
        if (this.isSearchDisabled()) {
            return;
        }
        this.router.navigate(['loading']).then(() => this.router.navigate([this.url.allProducts()], {
            queryParams: {
                query: this.form.get('siteSearch').value,
                perPage: 8,
                page: 1,
                sort: 'default',
            },
            queryParamsHandling: 'merge',
            replaceUrl: true,
        }));
        // this.router.navigate([this.url.allProducts()], {
        //     queryParams: {
        //         query: this.form.get('siteSearch').value,
        //     },
        // });
    }

    productToStringify(product: any): string {
        return JSON.stringify(product);
    }
}
