import { Component, EventEmitter, forwardRef, HostBinding, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Vehicle } from '../../../../interfaces/vehicle';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ShopApi } from '../../../../api';
import { VehicleApiNew } from '../../../../api/fake-api/vehicle.api';
import { CategoryService } from '../../../../services/category.service';


type ChangeFn = (_: Vehicle) => void;

type TouchedFn = () => void;

interface Select2Option {
    value: string;
    label: string;

}

interface VehicleSelectItemDef<T = any> {
    key: string;
    label: string;
    placeholder: string;
    optionsSource: (...args: any[]) => Observable<T[]>;
    serializeOptionFn?: (option: T, item: VehicleSelectItem<T>) => Select2Option;
    deserializeOptionFn?: (value: string, item: VehicleSelectItem<T>) => T;
}

interface VehicleSelectItem<T = any> extends VehicleSelectItemDef<T> {
    loading: boolean;
    options: T[];
    load: Subject<void>;
}

function makeItem<T>(itemDef: VehicleSelectItemDef<T>): VehicleSelectItem<T> {
    return {...itemDef, loading: false, options: [], load: new Subject<void>()};
}

@Component({
    selector: 'app-vehicle-select',
    templateUrl: './new-vehicle-select.component.html',
    styleUrls: ['./new-vehicle-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NewVehicleSelectComponent),
            multi: true,
        },
    ],
})
export class NewVehicleSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

    constructor(
        private vehicleApi: VehicleApiNew,
        private categoryService: CategoryService,
        private shopApi: ShopApi,
    ) {
    }

    private destroy$: Subject<void> = new Subject<void>();
    @Output()
    private submitEmitter: EventEmitter<void> = new EventEmitter<void>();

    value: Vehicle = null;

    form: FormGroup;

    items: VehicleSelectItem[] = [];


    @HostBinding('class.vehicle-select') classVehicleSelect = true;
    @ViewChild('modelSelect', {static: false}) modelSelectRef: any;


    changeFn: ChangeFn = () => {};

    touchedFn: TouchedFn = () => {};

    ngOnInit(): void {
        const vehicle = this.categoryService.vehicleSubject$.getValue();

        this.items = [
            makeItem({
                key: 'mfrId',
                label: 'INPUT_VEHICLE_BRAND_LABEL',
                placeholder: 'INPUT_VEHICLE_BRAND_LABEL',
                optionsSource: () => this.vehicleApi.getMakes().pipe(
                    map(x => x.map(y => {
                        return {
                            label: y.name, value: y.id,
                        };
                    })),
                    // tap((res) => {
                    //     console.log('res', res);
                    //     if (vehicle?.make) {
                    //         console.log('vehicle');
                    //         const option = res.find(x => x.value === vehicle.make);
                    //         this.items[0].load.next();
                    //         this.items[0].options = [option];
                    //         this.form.get('make').setValue(vehicle.make, {emitEvent: false});
                    //         this.items[0].load.complete();
                    //     } else {
                    //     }
                    // }),
                ),
            }),
            makeItem({
                key: 'vehicleModelSeriesId',
                label: 'INPUT_VEHICLE_SERIES_LABEL',
                placeholder: 'INPUT_VEHICLE_SERIES_LABEL',
                optionsSource: (make) => this.vehicleApi.getSeries(make).pipe(
                    map(x => {
                        return Object.keys(x).map(y => {
                            return {
                                label: y, value: x[y].join(','),
                            };
                        });
                    }),
                    // tap((res) => {
                    //     if (vehicle?.model && vehicle.make === make) {
                    //         const option = res.find(x => x.value === vehicle.model);
                    //         console.log('option', option);
                    //         this.items[1].load.next();
                    //         this.items[1].options = [option];
                    //         this.form.get('model').setValue(vehicle.model, {emitEvent: false});
                    //         this.items[1].load.complete();
                    //     } else {
                    //         console.log('no model');
                    //         this.items[1].load.next();
                    //         this.items[1].options = res;
                    //         this.items[1].load.complete();
                    //     }
                    // }),
                ),
            }),
            makeItem({
                key: 'year',
                label: 'INPUT_YEAR_LABEL',
                placeholder: 'INPUT_YEAR_LABEL',
                optionsSource: (make, model) => {
                    return model ? this.vehicleApi.getYears(make, model).pipe(
                        map(x => x.reverse().map(y => {
                            return {
                                label: `${y.year}`, value: `${y.year}`,
                            };
                        })),
                        // tap((res) => {
                        //     if (vehicle?.year && vehicle.make === make && vehicle.model === model) {
                        //         const option = res.find(x => x.value === vehicle.year);
                        //         this.items[2].load.next();
                        //         this.items[2].options = [option];
                        //         this.form.get('year').setValue(vehicle.year, {emitEvent: false});
                        //         this.items[2].load.complete();
                        //     } else {
                        //         console.log('no year');
                        //         this.items[2].load.next();
                        //         this.items[2].options = res;
                        //         this.items[2].load.complete();
                        //     }
                        // }),
                    ) : of(null);
                },
            }),
            makeItem({
                key: 'body',
                label: 'INPUT_BODY_LABEL',
                placeholder: 'INPUT_BODY_LABEL',
                optionsSource: (make, model, year) => {
                    return year ? this.vehicleApi.getBody(make, model, year).pipe(
                        map(x => x.map(y => {
                            return {
                                label: y.bodyStyle.charAt(0).toUpperCase() + y.bodyStyle.slice(1), value: y.bodyStyleKey,
                            };
                        })),
                        // tap(res => {
                        //     if (res.length === 1) {
                        //         this.items[3].load.next();
                        //         this.items[3].options = res;
                        //         this.form.get('body').setValue(res[0].value, {emitEvent: false});
                        //         this.items[3].load.complete();
                        //     }
                        // }),
                        // tap((res) => {
                        //     if (vehicle?.body && vehicle.make === make && vehicle.model === model && vehicle.year === year) {
                        //         const option = res.find(x => x.value === vehicle.body);
                        //         this.items[3].load.next();
                        //         this.items[3].options = [option];
                        //         this.form.get('body').setValue(vehicle.body, {emitEvent: false});
                        //         this.items[3].load.complete();
                        //     } else {
                        //         console.log('no body');
                        //         this.items[3].load.next();
                        //         this.items[3].options = res;
                        //         this.items[3].load.complete();
                        //     }
                        // }),
                    ) : of(null);
                },
            }),
            makeItem({
                key: 'engine',
                label: 'INPUT_ENGINE_LABEL',
                placeholder: 'INPUT_ENGINE_LABEL',
                optionsSource: (item, model, year, bodyStyleKey) => {
                    return bodyStyleKey ? this.vehicleApi.getEngines(item, model, year, bodyStyleKey).pipe(
                        map(x => x.map(y => {
                            // console.log('y', y);
                            return {
                                label: y.description, value: [y.capacityLiters, y.fuelTypeKey].join(' '),
                            };
                        })),
                        // tap((res) => {
                        //     if (vehicle?.engine && vehicle.make === item && vehicle.model === model
                        //         && vehicle.year === year && vehicle.body === bodyStyleKey) {
                        //         const option = res.find(x => x.value === vehicle.engine);
                        //         this.items[4].load.next();
                        //         this.items[4].options = [option];
                        //         this.form.get('engine').setValue(vehicle.engine, {emitEvent: false});
                        //         this.items[4].load.complete();
                        //     } else {
                        //         console.log('no engine');
                        //         this.items[4].load.next();
                        //         this.items[4].options = res;
                        //         this.items[4].load.complete();
                        //     }
                        // }),
                        // tap(res => {
                        //     console.log('engine', res);
                        //     if (res.length === 1) {
                        //         this.items[4].load.next();
                        //         this.items[4].options = res;
                        //         this.form.get('engine').setValue(res[0].value, {emitEvent: false});
                        //         this.items[4].load.complete();
                        //     }
                        // }),
                    ) : of(null);
                },
            }),
            makeItem({
                key: 'linkageTargetId',
                label: 'INPUT_MODIFICATION_LABEL',
                placeholder: 'INPUT_MODIFICATION_LABEL',
                optionsSource: (item, model, year, body, engine) => {
                    return engine ? this.vehicleApi.getModifications(item, model, year, body, engine).pipe(
                        map(x => x.map(y => {
                            return {
                                label: `
                                    <div>
                                    <b>${y.description} ${y.horsePowerFrom}/${y.kiloWattsFrom}</b>
                                    <div>${y.beginYearMonth?.replace('-', '/')} ${y.endYearMonth ? '-' + y.endYearMonth.replace('-', '/') : ''}</div>
                                    <small>(${y.engines.map(i => i.code).join(', ')})</small>
                                    </div>`, value: y.linkageTargetId,
                            };
                        })),
                        // tap(res => {
                        //     if (res.length === 1) {
                        //         this.items[5].load.next();
                        //         this.items[5].options = res;
                        //         this.form.get('modification').setValue(res[0].value, {emitEvent: false});
                        //         this.items[5].load.complete();
                        //     }
                        // }),
                        // tap((res) => res.length === 1 ? this.form.controls.modification.setValue(res) : res),
                    ) : of(null);
                },
            }),
        ];

        const controls: { [key: string]: FormControl } = {};

        this.items.forEach((item, index) => {
            this.makeOptionsLoader(item, index).pipe(
                takeUntil(this.destroy$),
            ).subscribe(options => item.options = options);

            controls[item.key] = new FormControl('none');
            controls[item.key].valueChanges.pipe(
                takeUntil(this.destroy$),
            ).subscribe(() => this.onItemValueChange(index));
        });

        this.form = new FormGroup(controls);

        this.onItemValueChange(0);
        this.items[0].load.next();
        this.form.controls[this.items[0].key].valueChanges
            .pipe(takeUntil(this.destroy$)).subscribe((value) => {
            // if (value !== vehicle?.make) {
            //     console.log('no make', value, vehicle?.make);
            //     this.form.controls[this.items[1].key].setValue('none');
            //     // this.form.get('make').setValue(null, {emitEvent: false});
            //     this.categoryService.vehicleSubject$.next({make: value, model: null, year: null, body: null, engine: null , modification: null});
            //     this.form.get('model').setValue('none', {emitEvent: true});
            //     this.items[1].load.complete();
            // }
            this.onChange(value, 'mfrId');
        });
        this.form.controls[this.items[1].key].valueChanges
            .pipe(takeUntil(this.destroy$)).subscribe((value) => this.onChange(value, 'vehicleModelSeriesId'));
        this.form.controls[this.items[2].key].valueChanges
            .pipe(takeUntil(this.destroy$)).subscribe((value) => this.onChange(value, 'year'));
        this.form.controls[this.items[3].key].valueChanges
            .pipe(takeUntil(this.destroy$)).subscribe((value) => this.onChange(value, 'body'));
        this.form.controls[this.items[4].key].valueChanges
            .pipe(takeUntil(this.destroy$)).subscribe((value) => this.onChange(value, 'engine'));
        this.form.controls[this.items[5].key].valueChanges
            .pipe(takeUntil(this.destroy$)).subscribe((value) => this.onChange(value, 'linkageTargetId'));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    registerOnChange(fn: ChangeFn): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: TouchedFn): void {
        this.touchedFn = fn;
    }

    writeValue(value: Vehicle): void {
        // if (value !== this.value) {
            this.value = value;
            this.changeFn(value);
        // }
    }

    serializeOption(option: any, item: VehicleSelectItem): Select2Option {
        if (item.serializeOptionFn) {
            return item.serializeOptionFn(option, item);
        }

        return option;
    }

    deserializeOption<T>(option: string, item: VehicleSelectItem<T>): T {
        if (item.deserializeOptionFn) {
            return item.deserializeOptionFn(option, item);
        }

        return option as any;
    }

    private setValue(value: Vehicle): void {
        // if (this.value !== value) {
            this.value = value;
            this.changeFn(value);
        // }
    }


    private onItemValueChange(index: number): void {
        const control: AbstractControl = this.form.get(this.items[index].key);
        this.items.slice(index + 1).forEach(nextItem => {
            nextItem.options = [];

            this.form.get(nextItem.key).setValue('none', {emitEvent: false});
            this.form.get(nextItem.key).disable({emitEvent: false});
        });

        if (control.value === 'none') {
            // this.setValue(null);
        } else {
            const nextItem = this.items.slice(index + 1, index + 2).pop();

            if (nextItem) {
                this.form.get(nextItem.key).enable({emitEvent: false});
                nextItem.load.next();
            } else {
                this.setValue(this.deserializeOption(control.value, this.items[index]));
            }
        }
    }

    private getItemValue(item: VehicleSelectItem): any {
        const value = this.form.get(item.key).value;
        if (value !== 'none' && item.deserializeOptionFn) {
            return item.deserializeOptionFn(value, item);
        }

        return value;
    }

    private getItemValues(items: VehicleSelectItem[]): any[] {
        return items.reduce((acc, prevItem) => [...acc, this.getItemValue(prevItem)], []);
    }

    private makeOptionsLoader(item: VehicleSelectItem, index: number): Observable<any[]> {
        return item.load.pipe(
            tap(() => item.loading = true),
            switchMap(() => {
                const args = this.getItemValues(this.items.slice(0, index));

                if (args.length > 0 && args.slice().pop() === 'none') {
                    return of([]);
                }

                return item.optionsSource(...args);
            }),
            tap(() => item.loading = false),
        );
    }

    onChange(value: string, key: string) {
        if (value) {
            switch (key) {
                case 'engine':
                    this.categoryService.vehicleSubject$.next({
                        ...this.categoryService.vehicleSubject$.value,
                        engine: value,
                    });
                    break;
                case 'year':
                    this.categoryService.vehicleSubject$.next({
                        ...this.categoryService.vehicleSubject$.value,
                        year: value,
                    });
                    break;
                case 'vehicleModelSeriesId':
                    this.categoryService.vehicleSubject$.next({
                        ...this.categoryService.vehicleSubject$.value,
                        vehicleModelSeriesId: value,
                    });
                    break;
                case 'body':
                    this.categoryService.vehicleSubject$.next({
                        ...this.categoryService.vehicleSubject$.value,
                        body: value,
                    });
                    break;
                case 'mfrId':
                    this.categoryService.vehicleSubject$.next({
                        ...this.categoryService.vehicleSubject$.value,
                        mfrId: value,
                    });
                    break;
                case 'linkageTargetId':
                    this.categoryService.vehicleSubject$.next({
                        ...this.categoryService.vehicleSubject$.value,
                        linkageTargetId: value,
                    });
                    this.submitEmitter.emit();
                    break;
            }
        }
    }
}
