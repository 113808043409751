<div class="dropcart__empty" *ngIf="!(cart.items$|async).length">
    {{ 'TEXT_YOUR_SHOPPING_CART_IS_EMPTY'|translate }}
</div>
<ng-container *ngIf="(cart.items$|async).length">
    <ul class="dropcart__list">
        <ng-container *ngFor="let item of cart.items$|async">
            <li class="dropcart__item">
                <div class="dropcart__item-image image image--type--product">
                    <a class="image__body" [queryParams]="{value: item.product.sku}" [routerLink]="url.product(item.product)" (click)="closeMenu.emit()">
                        <img *ngIf="!!item.product.images[0] else imagePlaceholder" class="image__tag" (error)="item.product.images[0]=''" [src]="item.product.images[0]" [alt]="item.product.name">
                        <ng-template #imagePlaceholder>
                            <img class="image__tag" src="../../../../../assets/images/placeholders/no-photo.png" alt="image placeholder">
                        </ng-template>
                    </a>
                </div>
                <div class="dropcart__item-info">
                    <div class="dropcart__item-name">
                        <a [queryParams]="{value: item.product.sku}" [routerLink]="url.product(item.product)" (click)="closeMenu.emit()">{{ item.product.name }}</a>
                    </div>
                    <ul *ngIf="item.options.length" class="dropcart__item-features">
                        <li *ngFor="let option of item.options">{{ option.name }}: {{ option.value }}</li>
                    </ul>
                    <div class="dropcart__item-meta">
                        <div class="dropcart__item-quantity">{{ item.quantity }}</div>
                        <div *ngIf="(isAuth$|async)" class="dropcart__item-price">{{ item.product.price|currencyFormat }}</div>
                    </div>
                </div>
                <button
                    appRemoveFromCart
                    type="button"
                    class="dropcart__item-remove"
                    [class.dropcart__item-remove--loading]="removeFromCart.inProgress"
                    (click)="removeFromCart.remove(item)"
                    #removeFromCart="removeFromCart"
                >
                    <app-icon icon="cross-10"></app-icon>
                </button>
            </li>
            <li class="dropcart__divider" role="presentation"></li>
        </ng-container>
    </ul>
    <div *ngIf="(isAuth$|async)" class="dropcart__totals">
        <table>
            <tbody>
            <tr *ngIf="(cart.totals$|async).length">
                <th>{{ 'TABLE_SUBTOTAL'|translate }}</th>
                <td>{{ cart.subtotal$|async|currencyFormat }}</td>
            </tr>
            <tr *ngFor="let total of cart.totals$|async">
                <th>{{ 'TABLE_TOTAL_' + total.title|translate }}</th>
                <td>{{ total.price|currencyFormat  }}</td>
            </tr>
            <tr>
                <th>{{ 'TABLE_TOTAL'|translate }}</th>
                <td>{{ cart.total$|async|currencyFormat }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="dropcart__actions">
        <a routerLink="/shop/cart" class="btn btn-secondary" (click)="closeMenu.emit()">{{ 'BUTTON_VIEW_CART'|translate }}</a>
        <a routerLink="/shop/checkout" class="btn btn-primary" (click)="closeMenu.emit()">{{ 'BUTTON_CHECKOUT'|translate }}</a>
    </div>
</ng-container>
