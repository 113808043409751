import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { ShopCategory, TecDocCategory } from '../../interfaces/category';
import { Product } from '../../interfaces/product';
import { Brand } from '../../interfaces/brand';
import { ProductsList, ReviewsList } from '../../interfaces/list';
import { Review } from '../../interfaces/review';
import { Order } from '../../interfaces/order';
import {
    AddProductReviewData,
    CheckoutData,
    GetBrandsOptions,
    GetCategoriesOptions, GetCategoryBySlugOptions,
    GetProductReviewsOptions,
    GetProductsListOptions,
    ShopApi,
} from '../base';
import {
    addProductReview,
    checkout,
    getFeaturedProducts,
    getLatestProducts,
    getPopularProducts,
    getProductAnalogs,
    getProductBySlug,
    getProductReviews,
    getRelatedProducts,
    getSpecialOffers,
    getTopRatedProducts,
} from '../../../fake-server/endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Vehicle } from '../../interfaces/vehicle';
import { catchError, delay, filter, map, pluck, retryWhen, take, takeUntil, tap } from 'rxjs/operators';
import { HelperUtilsService } from '../../functions/helpers/helper-utils.service';
import { LanguageService } from '../../modules/language/services/language.service';
import { nameToSlug } from '../../../fake-server/utils';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fitImageCalcForAllImages } from '../../functions/helpers/image-helper.service';
import { CategoryService } from '../../services/category.service';


@Injectable()
export class FakeShopApi extends ShopApi implements OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();
    currentLanguage: string;

    constructor(private http: HttpClient,
                private helperUtils: HelperUtilsService,
                private languageService: LanguageService,
                private toastr: ToastrService,
                private router: Router,
                private categoryService: CategoryService,
    ) {
        super();
        this.languageService.current$.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
            this.currentLanguage = lang.code;
        });
    }

    getCategoryBySlug(slug: string, shopCategoriesList: ShopCategory[], options?: GetCategoryBySlugOptions): Observable<ShopCategory> {
        return this.categoryService.getCategoryBySlug(slug, this.categoryService.shopCategoriesList.value, options);
    }

    getCategories(shopCategoriesTree: ShopCategory[],
                  shopCategoriesList: ShopCategory[],
                  options?: GetCategoriesOptions): Observable<ShopCategory[]> {
        return this.categoryService.getCategories(shopCategoriesTree, shopCategoriesList, options);
    }

    getVehicleByVIN(vin: string): Observable<any[]> {
        return this.http.post<TecDocCategory[]>(`${environment.tecDoc}/VIN`, {
            vin,
            lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
        });
    }

    getCategoriesFromTecDoc(mfrId?: number, vehicleModelSeriesId?: number, linkageTargetId?: number): Observable<TecDocCategory[]> {
        return this.http.post<TecDocCategory[]>(`${environment.tecDoc}`, {
            request_json: {
                getArticles: {
                    perPage: 0,
                    page: 1,
                    articleCountry: 'ru',
                    mfrId: Number(this.categoryService.vehicleSubject$?.getValue()?.mfrId) || mfrId || 0,
                    linkageTargetId: Number(this.categoryService.vehicleSubject$?.getValue()?.linkageTargetId) || linkageTargetId || 0,
                    vehicleModelSeriesId: Number(this.categoryService.vehicleSubject$?.getValue()?.vehicleModelSeriesId) || vehicleModelSeriesId || 0,
                    linkageTargetType: 'P',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    includeAll: false,
                    // assemblyGroupNodeIds: 102697,
                    assemblyGroupFacetOptions: {
                        enabled: true,
                        assemblyGroupType: 'P',
                        includeCompleteTree: false,
                    },
                },
            },
            get: 'Category',
        }).pipe(
            tap(res => console.log('getCategoriesFromTecDoc res', res)),
        );
    }

    getCategoriesFromTecDocByBrand(dataSupplierIds?: string): Observable<TecDocCategory[]> {
        return this.http.post<TecDocCategory[]>(`${environment.tecDoc}`, {
            request_json: {
                getArticles: {
                    perPage: 0,
                    page: 1,
                    articleCountry: dataSupplierIds === '30' ? 'am' : 'ru',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    includeAll: false,
                    dataSupplierIds,
                    // assemblyGroupNodeIds: 102697,
                    assemblyGroupFacetOptions: {
                        enabled: true,
                        assemblyGroupType: 'P',
                        includeCompleteTree: false,
                    },
                },
            },
            get: 'Category',
        }).pipe(
            tap(res => console.log('getCategoriesFromTecDoc res', res)),
        );
    }

    getBrands(options?: GetBrandsOptions): Observable<Brand[]> {
        return this.http.post<Brand[]>(`${environment.tecDoc}`, {
            request_json: {
                getBrands: {
                    articleCountry: 'ru',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    provider: 23629,
                    includeAll: true,
                    includeDataSupplierLogo: true,
                },
            },
        }).pipe(
            pluck('data', 'data', 'array'),
            map((res: any) => {
                return res.map(item => {
                    return {
                        slug: nameToSlug(item?.mfrName),
                        name: item?.mfrName,
                        image: item?.dataSupplierLogo?.imageURL400,
                        country: 'ru',
                        dataSupplierId: item?.dataSupplierId,
                    } as Brand;
                }).filter((item) => (item.image));
            }),
            retryWhen(errors => {
                return errors.pipe(
                    delay(2000),
                    tap(error => console.error('Retrying getMakes due to error:', error)),
                    take(3),
                );
            }),
        );
        // return getBrands(options);
    }

    getProductsListByQuery(query: string, dataSupplierIds?: string, options?: GetProductsListOptions): Observable<any> {
        console.log('options', options);
        if (query.length > 0) {
            return this.http.post<any>(`${environment.tecDoc}`, {
                request_json: {
                    getArticles: {
                        lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                        provider: 23629,
                        articleCountry: dataSupplierIds === '30' ? 'am' : 'ru',
                        searchQuery: query,
                        searchType: 99,
                        perPage: Number(options.perPage),
                        page: Number(options.page),
                        includeAll: true,
                        dataSupplierIds,
                    },
                },
                get: 'Product',
            }).pipe(
                tap(res => {
                    if (res?.data?.message) {
                        this.toastr.error(res?.data?.message);
                    }
                }),
                retryWhen(errors => {
                    return errors.pipe(
                        delay(2000),
                        tap(error => console.error('Retrying getMakes due to error:', error)),
                        take(3),
                    );
                }),
                filter((res) => !!res && !!res.data && !!res.data.articles),
                map((res) => {
                    const pages = Math.ceil(res.data.totalMatchingArticles / options.perPage);
                    const items = res.data.articles.map(
                        (data: any) => ({
                            id: data.genericArticles[0].legacyArticleId,
                            name: data.genericArticles[0]?.genericArticleDescription,
                            slug: nameToSlug(data.genericArticles[0]?.genericArticleDescription),
                            mfrName: data.mfrName,
                            vehicleModelSeriesIds: data.vehicleModelSeriesId,
                            dataSupplierIds: data.dataSupplierId,
                            images: data.images.map(image => image.imageURL1600),
                            brand: {name: data.mfrName, slug: nameToSlug(data.mfrName)},
                            sku: data?.articleNumber,
                            oemNumbers: data.oemNumbers,
                            price: data?.price,
                            stock: data.inStock ? 'in-stock' : 'out-of-stock',
                            status: data?.misc?.articleStatusDescription,
                            description: data?.articleText[0]?.text ?? 'No Description',
                            productImages: fitImageCalcForAllImages(data.images),
                            specification: data?.articleCriteria
                                ?.map(criteria => ({
                                        desc: criteria?.criteriaDescription,
                                        value: criteria?.rawValue,
                                        unit: criteria?.criteriaUnitDescription,
                                    }),
                                ),
                        } as unknown as Product),
                    );
                    return {
                        page: options.page ?? 1,
                        sort: options.sort ?? 'default',
                        pages: pages >= 10000 / Number(options.perPage) ? 10000 / Math.ceil(options.perPage) : pages,
                        perPage: Number(options.perPage) ?? 8,
                        total: res.data.totalMatchingArticles,
                        to: res.data.totalMatchingArticles < options.page * options.perPage ?
                            res.data.totalMatchingArticles : options.page * options.perPage
                            ?? res.page * res.perPage,
                        from: (options.page - 1) * options.perPage + 1 ?? 1,
                        items,
                    } as unknown as ProductsList;
                }),
                catchError((error) => {
                    console.log(error);
                    this.router.navigateByUrl('/');
                    return of({} as ProductsList);
                }),
            );
        }
        // return getProductsList(options);
    }

    getProductsListByAuto(options?: GetProductsListOptions,
                          assemblyGroupNodeIds?: string,
                          mfrId?: string,
                          vehicleModelSeriesId?: string,
                          linkageTargetId?: string): Observable<ProductsList> {
        return this.http.post<ProductsList>(`${environment.tecDoc}`, {
            request_json: {
                getArticles: {
                    perPage: 8,
                    page: 1,
                    articleCountry: 'ru',
                    mfrId: mfrId ?? Number(this.categoryService.vehicleSubject$?.getValue()?.mfrId) ?? 0,
                    vehicleModelSeriesId: vehicleModelSeriesId ??
                        Number(this.categoryService.vehicleSubject$?.getValue()?.vehicleModelSeriesId) ?? 0,
                    linkageTargetId: linkageTargetId ?? Number(this.categoryService.vehicleSubject$?.getValue()?.linkageTargetId) ?? 0,
                    linkageTargetType: 'P',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    includeDataSupplierFacets: true,
                    assemblyGroupNodeIds: Number(assemblyGroupNodeIds) ?? 0,
                    includeImages: true,
                    includeArticleText: true,
                    includeGenericArticles: true,
                    includeArticleCriteria: true,
                    includePrices: true,
                },
            },
            get: 'Product',
        }).pipe(
            map((res: any) => {
                const pages = Math.ceil(res.data.totalMatchingArticles / options.perPage);
                const items = res.data.articles.map(data => {
                    return {
                        id: data.genericArticles[0].legacyArticleId,
                        name: data.genericArticles[0]?.genericArticleDescription,
                        sku: data.articleNumber,
                        slug: nameToSlug(data.genericArticles[0]?.genericArticleDescription),
                        price: data.price, compareAtPrice: null,
                        images: data.images.map(image => image.imageURL1600),
                        compatibility: 'all',
                        partNumber: data.articleNumber,
                        vehicleModelSeriesIds: data.vehicleModelSeriesId,
                        dataSupplierIds: data.dataSupplierId,
                        excerpt: data.genericArticles[0]?.genericArticleDescription,
                        stock: data.inStock ? 'in-stock' : 'out-of-stock',
                        brand: {name: data.mfrName, slug: nameToSlug(data.mfrName)},
                        description: data?.articleText?.length > 0 ? `<h2>${data.articleText[0]?.informationTypeDescription}</h2>
                                  <p>${data.articleText[0]?.text.join(' ')}</p>` :
                            `<h2>${this.helperUtils.getTranslation('NO_DESCRIPTION')}</h2>`,
                        attributes: data.articleCriteria.map((criteria) => ({
                            name: criteria.criteriaDescription,
                            slug: nameToSlug(criteria.criteriaDescription),
                            // TODO add featured and attributes to show them in the grid
                            values: [{
                                name: criteria.formattedValue,
                                slug: nameToSlug(criteria.formattedValue),
                            }],
                        })),
                        type: {
                            slug: 'default',
                            name: 'Default',
                            attributeGroups: data.articleCriteria.map((criteria) => ({
                                name: criteria.criteriaDescription,
                                slug: nameToSlug(criteria.criteriaDescription),
                            })),
                        },
                    } as unknown as Product;
                });
                return {
                    page: options.page || 1,
                    sort: options.sort ?? res.sort,
                    pages: pages >= 10000 / options.perPage ? 10000 / Math.ceil(options.perPage) : pages,
                    perPage: Number(options.perPage) ?? 8,
                    total: res.data.totalMatchingArticles,
                    to: res.data.totalMatchingArticles < options.page * options.perPage ?
                        res.data.totalMatchingArticles : options.page * options.perPage
                        ?? res.page * res.perPage,
                    from: (options.page - 1) * options.perPage + 1 ?? 1,
                    items,
                } as unknown as ProductsList;
            }),
            tap(res => {
                console.log('getProductsListByAuto res', res);
            }),
            map((res: ProductsList) => {
                const sorted: ProductsList = res;
                sorted.items = this.helperUtils.sortByName(res.items, options.sort);
                return sorted;
            }),
            catchError((error) => {
                this.router.navigateByUrl('/');
                return of({} as ProductsList);
            }),
        );
    }

    getProductsListByBrands(options?: GetProductsListOptions,
                            assemblyGroupNodeIds?: string,
                            dataSupplierIds?: string): Observable<ProductsList> {
        return this.http.post<ProductsList>(`${environment.tecDoc}`, {
            request_json: {
                getArticles: {
                    perPage: 8,
                    page: 1,
                    articleCountry: dataSupplierIds === '30' ? 'am' : 'ru',
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    includeDataSupplierFacets: true,
                    dataSupplierIds,
                    assemblyGroupNodeIds: Number(assemblyGroupNodeIds) ?? 0,
                    includeImages: true,
                    includeArticleText: true,
                    includeGenericArticles: true,
                    includeArticleCriteria: true,
                    includePrices: true,
                },
            },
            get: 'Product',
        }).pipe(
            map((res: any) => {
                const pages = Math.ceil(res.data.totalMatchingArticles / options.perPage);
                const items = res.data.articles.map(data => {
                    return {
                        id: data.genericArticles[0].legacyArticleId,
                        name: data.genericArticles[0]?.genericArticleDescription,
                        sku: data.articleNumber,
                        slug: nameToSlug(data.genericArticles[0]?.genericArticleDescription),
                        price: data.price, compareAtPrice: null,
                        images: data.images.map(image => image.imageURL1600),
                        compatibility: 'all',
                        partNumber: data.articleNumber,
                        vehicleModelSeriesIds: data.vehicleModelSeriesId,
                        dataSupplierIds: data.dataSupplierId,
                        excerpt: data.genericArticles[0]?.genericArticleDescription,
                        stock: data.inStock ? 'in-stock' : 'out-of-stock',
                        brand: {name: data.mfrName, slug: nameToSlug(data.mfrName)},
                        description: data?.articleText?.length > 0 ? `<h2>${data.articleText[0]?.informationTypeDescription}</h2>
                                  <p>${data.articleText[0]?.text.join(' ')}</p>` :
                            `<h2>${this.helperUtils.getTranslation('NO_DESCRIPTION')}</h2>`,
                        attributes: data.articleCriteria.map((criteria) => ({
                            name: criteria.criteriaDescription,
                            slug: nameToSlug(criteria.criteriaDescription),
                            // TODO add featured and attributes to show them in the grid
                            values: [{
                                name: criteria.formattedValue,
                                slug: nameToSlug(criteria.formattedValue),
                            }],
                        })),
                        oemNumbers: data.oemNumbers,
                        type: {
                            slug: 'default',
                            name: 'Default',
                            attributeGroups: data.articleCriteria.map((criteria) => ({
                                name: criteria.criteriaDescription,
                                slug: nameToSlug(criteria.criteriaDescription),
                            })),
                        },
                    } as unknown as Product;
                });
                return {
                    page: options.page || 1,
                    sort: options.sort ?? res.sort,
                    pages: pages >= 10000 / options.perPage ? 10000 / Math.ceil(options.perPage) : pages,
                    perPage: Number(options.perPage) ?? 8,
                    total: res.data.totalMatchingArticles,
                    to: res.data.totalMatchingArticles < options.page * options.perPage ?
                        res.data.totalMatchingArticles : options.page * options.perPage
                        ?? res.page * res.perPage,
                    from: (options.page - 1) * options.perPage + 1 ?? 1,
                    items,
                } as unknown as ProductsList;
            }),
            map((res: ProductsList) => {
                const sorted: ProductsList = res;
                sorted.items = this.helperUtils.sortByName(res.items, options.sort);
                return sorted;
            }),
            catchError((error) => {
                this.router.navigateByUrl('/');
                return of({} as ProductsList);
            }),
        );
    }

    getAccessoryList(articleCountry: string, articleId: number): Observable<any> {
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                getArticleAccessoryList4: {
                    provider: 23629,
                    articleCountry,
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    articleId,
                },
            },
        });
    }

    getPartList(articleCountry: string, articleId: number): Observable<any> {
        return this.http.post<any>(`${environment.tecDoc}`, {
            request_json: {
                getArticlePartList: {
                    provider: 23629,
                    articleCountry,
                    lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                    articleId,
                },
            },
        });
    }


    getProductBySlug(slug: string): Observable<Product> {
        return getProductBySlug(slug);
    }

    getProductReviews(productId: number, options?: GetProductReviewsOptions): Observable<ReviewsList> {
        return getProductReviews(productId, options);
    }

    addProductReview(productId: number, data: AddProductReviewData): Observable<Review> {
        return addProductReview(productId, data);
    }

    getProductAnalogs(productId: number): Observable<Product[]> {
        return getProductAnalogs(productId);
    }

    getRelatedProducts(productId: number, limit: number): Observable<Product[]> {
        return getRelatedProducts(productId, limit);
    }

    getFeaturedProducts(categorySlug: string, limit: number): Observable<Product[]> {
        return getFeaturedProducts(categorySlug, limit);
    }

    getPopularProducts(categorySlug: string, limit: number): Observable<Product[]> {
        return getPopularProducts(categorySlug, limit);
    }

    getTopRatedProducts(categorySlug: string, limit: number): Observable<Product[]> {
        return getTopRatedProducts(categorySlug, limit);
    }

    getSpecialOffers(limit: number): Observable<Product[]> {
        return getSpecialOffers(limit);
    }

    getLatestProducts(limit: number): Observable<Product[]> {
        return getLatestProducts(limit);
    }

    getSearchSuggestions(query: { searchText: string, productShowCount: number }): Observable<any> {
        if (query.searchText.length > 0) {
            return this.http.post<any[]>(`${environment.tecDoc}`, {
                request_json: {
                    getArticles: {
                        lang: this.currentLanguage === 'am' ? 'ru' : this.currentLanguage,
                        provider: 23629,
                        articleCountry: 'ru',
                        searchQuery: query.searchText,
                        searchType: 99,
                        perPage: 8,
                        page: 1,
                        includeAll: true,
                    },
                },
            }).pipe(
                retryWhen(errors => {
                    return errors.pipe(
                        delay(2000),
                        tap(error => console.error('Retrying getMakes due to error:', error)),
                        take(3),
                    );
                }),
            );
        }
        // return getSearchSuggestions(query, options);
    }

    getCategoriesBrandFilter(brandFilter: string): Observable<TecDocCategory[]> {
        return this.getCategoriesFromTecDocByBrand(brandFilter).pipe(
            pluck('data'),
            tap((categories: any[]) => {
                const shopCategories = categories.map(category => {
                    return {
                        type: 'shop',
                        layout: 'categories',
                        id: category.assemblyGroupNodeId,
                        name: category.assemblyGroupName,
                        slug: nameToSlug(category.assemblyGroupName),
                        image: category.image,
                        items: category.count,
                        children: category.children.filter(child => child.count > 0),
                    };
                }) as ShopCategory[];
                if (shopCategories.length > 0) {
                    this.categoryService.shopCategoriesTree.next(shopCategories);
                    this.categoryService.shopCategoriesList.next(this.categoryService.flatTree(shopCategories));
                }
            }),
        );
    }

    getCategoriesCarFilter(mfrId: number, vehicleModelSeriesId: number, linkageTargetId: number): Observable<TecDocCategory[]> {
        return this.getCategoriesFromTecDoc(mfrId, vehicleModelSeriesId, linkageTargetId).pipe(
            pluck('data'),
            tap(res => console.log('res', res)),
            tap((categories: any[]) => {
                const shopCategories = categories.map(category => {
                    return {
                        type: 'shop',
                        layout: 'categories',
                        id: category.assemblyGroupNodeId,
                        name: category.assemblyGroupName,
                        slug: nameToSlug(category.assemblyGroupName),
                        image: category.image,
                        items: category.count,
                        children: category.children.filter(child => child.count > 0),
                    };
                }) as ShopCategory[];
                if (shopCategories.length > 0) {
                    console.log('getCategoriesCarFilter');
                    this.categoryService.shopCategoriesTree.next(shopCategories);
                    this.categoryService.shopCategoriesList.next(this.categoryService.flatTree(shopCategories));
                }
            }),
        );
    }

    checkout(data: CheckoutData): Observable<Order> {
        return checkout(data);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
