import { Injectable } from '@angular/core';
import { blogCategoriesTree } from '../../fake-server/database/categories';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { BaseCategory, BlogCategory, Category, ShopCategory } from '../interfaces/category';
import { tap } from 'rxjs/operators';
import { GetBlogCategoriesOptions, GetCategoriesOptions, GetCategoryBySlugOptions } from '../api';
import { HttpErrorResponse } from '@angular/common/http';
import { clone } from '../../fake-server/utils';
import { Vehicle } from '../interfaces/vehicle';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    public vehicleSubject$: BehaviorSubject<Vehicle> = new BehaviorSubject<Vehicle>(null);
    shopCategoriesTree: BehaviorSubject<ShopCategory[]> = new BehaviorSubject<ShopCategory[]>([]);
    shopCategoriesList: BehaviorSubject<ShopCategory[]> = new BehaviorSubject<ShopCategory[]>([]);

    constructor() {
        this.shopCategoriesTree.pipe(tap((categories) => console.log('categories', categories))).subscribe();
    }

    prepareCategory<T extends BaseCategory>(category: T, depth?: number): T {
        let children;

        if (depth && depth > 0) {
            children = category.children.map(x => this.prepareCategory(x, depth - 1));
        }

        return JSON.parse(JSON.stringify({
            ...category,
            parent: category.parent ? this.prepareCategory(category.parent) : (category.parent === null ? null : undefined),
            children,
        }));
    }

    getCategoryBySlug(slug: string, categoriesList, options?: GetCategoryBySlugOptions): Observable<ShopCategory> {
        options = options || {};

        const category = categoriesList.find(x => x.slug === slug);

        if (!category) {
            return throwError(new HttpErrorResponse({status: 404, statusText: 'Page Not Found'}));
        }

        return of(this.prepareCategory(category, options.depth));
    }

    getCategories(categoriesTree: ShopCategory[], categoriesList: ShopCategory[], options?: GetCategoriesOptions): Observable<ShopCategory[]> {
        let categories = categoriesTree.slice(0);
        const depth = options.depth || 0;

        if (options.parent) {
            const parent = categoriesList.find(x => x.slug === options.parent.slug);

            if (parent) {
                categories = parent.children;
            }
        } else if (options.slugs) {
            categories = categoriesList.filter(x => options.slugs.includes(x.slug));
        }

        categories = categories.map(x => this.prepareCategory(x, depth));

        return of(clone(categories));
    }

    getBlogCategories(options: GetBlogCategoriesOptions): Observable<BlogCategory[]> {
        let categories = blogCategoriesTree.slice(0);
        const depth = options.depth || 0;

        categories = categories.map(x => this.prepareCategory(x, depth));

        return of(clone(categories));
    }

    resetCategories() {
        this.shopCategoriesTree.next([]);
        this.shopCategoriesList.next([]);
    }

    flatTree<T extends Category>(categories: T[]): T[] {
        let result = [];
        categories?.forEach(category => result = [...result, category, ...this.flatTree(category.children as Category[])]);

        return result;
    }
}
