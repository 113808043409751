import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { CartService } from '../../../../services/cart.service';
import { UrlService } from '../../../../services/url.service';
import { Observable } from 'rxjs';
import { AccountApi } from '../../../../api';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-dropcart',
    templateUrl: './dropcart.component.html',
    styleUrls: ['./dropcart.component.scss'],
})
export class DropcartComponent {
    isAuth$: Observable<boolean>;

    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('class.dropcart') classDropcart = true;

    constructor(
        public cart: CartService,
        public url: UrlService,
        private account: AccountApi,
    ) {
        this.isAuth$ = this.account.user$.pipe(map(x => x !== null));
    }
}
