<ng-container *ngIf="showGallery">
    <div class="product-gallery__featured">
        <button type="button" class="product-gallery__zoom" (click)="openPhotoSwipe(currentItem)">
            <app-icon icon="zoom-in-24"></app-icon>
        </button>
        <owl-carousel-o *ngIf="items.length > 0 else imagePlaceholder"  [options]="carouselOptions" (changed)="featuredCarouselTranslated($event)" appOwlPreventClick #featuredCarousel>
            <ng-template *ngFor="let item of items" carouselSlide [id]="item.id">
                <div class="image image--type--product">
                    <a class="image__body" (click)="onFeaturedImageClick($event, item);" [href]="item.image" target="_blank">
                        <img  class="image__tag" [src]="item.image" alt="" #imageElement>
                    </a>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="product-gallery__thumbnails">
        <owl-carousel-o
            appOwlPreventClick
            [options]="thumbnailsCarouselOptions"
            [appFakeSlides]="items.length"
            #fakeSlides="appFakeSlides"
            #thumbnailsCarousel
        >
            <ng-template *ngFor="let item of items" carouselSlide [id]="item.id">
                <div
                    class="product-gallery__thumbnails-item image image--type--product"
                    [class.product-gallery__thumbnails-item--active]="item === currentItem"
                    (click)="onThumbnailImageClick(item)"
                >
                    <div class="image__body">
                        <img *ngIf="!!item.image else imagePlaceholder" class="image__tag" (error)="item.image=false" [src]="item.image" alt="">
                        <ng-template #imagePlaceholder>
                            <img class="image__tag" src="../../../../../assets/images/placeholders/no-photo.png" alt="image placeholder">
                        </ng-template>
                    </div>
                </div>
            </ng-template>
            <ng-template *ngFor="let i of fakeSlides.slides" carouselSlide [id]="'fake-slide-'+i"></ng-template>
        </owl-carousel-o>
    </div>
</ng-container>

<ng-template #imagePlaceholder>
    <img [style.width.%]="100" src="../../../../../assets/images/placeholders/no-photo.png" alt="image placeholder">
</ng-template>
