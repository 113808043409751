import { APP_INITIALIZER, NgModule } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { Event, Router, Scroll } from '@angular/router';


// modules (angular)
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
// modules (third-party)
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// modules
import { AppRoutingModule } from './app-routing.module';
import { CurrencyModule } from './modules/currency/currency.module';
import { FakeApiModule } from './api';
import { FooterModule } from './modules/footer/footer.module';
import { HeaderModule } from './modules/header/header.module';
import { LanguageModule } from './modules/language/language.module';
import { MobileModule } from './modules/mobile/mobile.module';
import { SharedModule } from './modules/shared/shared.module';

// components
import { AppComponent } from './app.component';
import { RootComponent } from './components/root/root.component';

// pages
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { FakeAccountApi } from './api/fake-api/fake-account.api';
import {ScrollToModule} from "@nicky-lenaers/ngx-scroll-to";

export function initApp(translateService: TranslateService): () => Promise<any> {
    return (): Promise<any> => {
        return translateService.getTranslation(translateService.currentLang).toPromise();
    };
}

@NgModule({
    declarations: [
        // components
        AppComponent,
        RootComponent,
        // pages
        PageNotFoundComponent,
    ],
    imports: [
        // modules (angular)
        BrowserAnimationsModule,
        ScrollToModule.forRoot(),
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        // modules (third-party)
        ModalModule.forRoot(),
        ToastrModule.forRoot(),
        TooltipModule.forRoot(),
        TranslateModule.forChild(),
        // modules
        AppRoutingModule,
        CurrencyModule.config({
            default: 'USD',
            currencies: [
                // {
                //     symbol: '$',
                //     name: 'US Dollar',
                //     code: 'USD',
                //     rate: 1,
                // },
                {
                    symbol: '֏',
                    name: 'ARMENIAN_DRAM',
                    code: 'AMD',
                    rate: 1,
                    formatFn: (value) => {
                        const digits = [].slice.call(Math.round(value).toFixed()).reverse();
                        const parts = [];

                        while (digits.length) {
                            parts.push(digits.splice(0, 3).reverse().join(''));
                        }

                        return parts.reverse().join(' ') + ' ֏';
                    },
                },
                // {
                //     symbol: '₽',
                //     name: 'Russian Ruble',
                //     code: 'RUB',
                //     rate: 71,
                //     formatFn: (value) => {
                //         const digits = [].slice.call(Math.round(value).toFixed()).reverse();
                //         const parts = [];
                //
                //         while (digits.length) {
                //             parts.push(digits.splice(0, 3).reverse().join(''));
                //         }
                //
                //         return parts.reverse().join(' ') + ' руб';
                //     },
                // },
            ],
        }),
        FakeApiModule,
        FooterModule,
        HeaderModule,
        LanguageModule.config({
            default: 'ru',
            languages: [
                {
                    code: 'am',
                    name: 'Հայերեն',
                    image: 'assets/images/languages/language-3.png',
                    direction: 'ltr',
                    disabled: false,
                },
                {
                    code: 'en',
                    name: 'English',
                    image: 'assets/images/languages/language-1.png',
                    direction: 'ltr',
                    disabled: false,
                },
                {
                    code: 'ru',
                    name: 'Русский',
                    image: 'assets/images/languages/language-2.png',
                    direction: 'ltr',
                    disabled: false,
                },
            ],
        }),
        MobileModule,
        SharedModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [TranslateService],
            multi: true,
        },
        FakeAccountApi,
    ],
})
export class AppModule {
    constructor(router: Router, viewportScroller: ViewportScroller) {
        router.events.pipe(
            filter((e: Event): e is Scroll => e instanceof Scroll),
        ).subscribe(e => {
            if (e.position) {
                viewportScroller.scrollToPosition(e.position);
            } else if (!e.anchor) {
                viewportScroller.scrollToPosition([0, 0]);
            }
        });
    }
}
