<ng-container
    appAddToCart
    appAddToWishlist
    appAddToCompare
    #cart="addToCart"
    #wishlist="addToWishlist"
    #compare="addToCompare"
>
    <div class="product-card__actions-list">
        <button
            class="product-card__action product-card__action--quickview"
            type="button"
            [class.product-card__action--loading]="showingQuickview"
            [attr.aria-label]="'BUTTON_QUICKVIEW'|translate"
            (click)="showQuickview()"
        >
            <app-icon icon="quickview-16"></app-icon>
        </button>
        <ng-container *ngIf="!exclude.includes('actions')">
            <button
                type="button"
                class="product-card__action product-card__action--wishlist"
                [class.product-card__action--loading]="wishlist.inProgress"
                [attr.aria-label]="'BUTTON_ADD_TO_WISHLIST'|translate"
                (click)="wishlist.add(product)"
            >
                <app-icon icon="wishlist-16"></app-icon>
            </button>
            <button
                type="button"
                class="product-card__action product-card__action--compare"
                [class.product-card__action--loading]="compare.inProgress"
                [attr.aria-label]="'BUTTON_ADD_TO_COMPARE'|translate"
                (click)="compare.add(product)"
            >
                <app-icon icon="compare-16"></app-icon>
            </button>
        </ng-container>
    </div>

    <div class="product-card__image">
        <div class="image image--type--product">
            <a [routerLink]="url.product(product)" [queryParams]="{value: product.sku, dataSupplierIds: product.dataSupplierIds}" class="image__body">
                <ng-container *ngIf="product.images.length > 0 else nophoto">
                    <img class="image__tag" [src]="product.images[0]" (error)="product.images[0]=''" [alt]="product.name">
                </ng-container>
            </a>
        </div>

        <app-status-badge
            *ngIf="!exclude.includes('status-badge') && vehicle"
            class="product-card__fit"
            [type]="{
                'all': 'unknown',
                'fit': 'success',
                'not-fit': 'failure',
                'unknown': 'warning'
            }[compatibility()]"
            [icon]="{
                'all': 'success',
                'fit': 'success',
                'not-fit': 'failure',
                'unknown': 'failure'
            }[compatibility()]"
            [text]="{
                'all': 'TEXT_COMPATIBILITY_ALL',
                'fit': 'TEXT_COMPATIBILITY_FIT',
                'not-fit': 'TEXT_COMPATIBILITY_NOT_FIT',
                'unknown': 'TEXT_COMPATIBILITY_UNKNOWN'
            }[compatibility()]|translate:{year: vehicle.year, make: vehicle.mfrId, model: vehicle.vehicleModelSeriesId}"
            [tooltipContent]="{
                'all': 'TEXT_COMPATIBILITY_ALL',
                'fit': 'TEXT_COMPATIBILITY_FIT',
                'not-fit': 'TEXT_COMPATIBILITY_NOT_FIT',
                'unknown': 'TEXT_COMPATIBILITY_UNKNOWN'
            }[compatibility()]|translate:{year: vehicle.year, make: vehicle.mfrId, model: vehicle.vehicleModelSeriesId}"
        ></app-status-badge>
    </div>

    <div class="product-card__info">
        <div *ngIf="!exclude.includes('meta')" class="product-card__meta">
            <span class="product-card__meta-title">{{ 'TEXT_SKU'|translate }}:</span> {{ product.sku }}
        </div>

        <div class="product-card__name">
            <div>
                <div *ngIf="product?.badges?.length > 0" class="product-card__badges">
                    <div *ngFor="let badge of product.badges" class="tag-badge tag-badge--{{ badge }}">{{ badge }}</div>
                </div>
                <a [routerLink]="url.product(product)" [queryParams]="{value: product.sku, dataSupplierIds: product.dataSupplierIds}">{{ product.name }}</a>
            </div>
        </div>

<!--        <div *ngIf="product.rating" class="product-card__rating">-->
<!--            <app-rating class="product-card__rating-stars" [value]="product.rating"></app-rating>-->
<!--            <div class="product-card__rating-label">-->
<!--                {{ 'TEXT_RATING_LABEL'|translate:{rating: product.rating, reviews: product.reviews} }}-->
<!--            </div>-->
<!--        </div>  -->

        <div class="image image--type--brand">
            <a class="image__body">
<!--                <ng-container *ngIf="!!product?.brand?.image else noPhotoBrand">-->
<!--                    <img class="image__tag" (error)="product.brand.image=''" [src]="product?.brand?.image" [alt]="product?.brand?.name">-->
<!--                </ng-container>-->
<!--                <ng-template #noPhotoBrand>-->
<!--                    <img class="image__tag" src="assets/images/products/no-brand.jpeg" alt="no brand photo">-->
<!--                </ng-template>-->

<!--  TODO check where it crushes               [style.margin-left.px]="this.classProductCardLayoutGrid || this.classProductCardLayoutFeatures ? 15 : 0"-->
                <div *ngIf="product.brand.name" class="product-card__brand">
                <span class="product-card__meta-title"></span> <span>{{ product.brand.name }}</span>
                </div>
            </a>
        </div>

<!--        <div *ngIf="!exclude.includes('features') && featuredAttributes.length > 0" class="product-card__features">-->
<!--            <ul>-->
<!--                <li *ngFor="let attribute of featuredAttributes">-->
<!--                    {{ attribute.name }}:-->
<!--                    <ng-container *ngFor="let value of attribute.values; let last = last">-->
<!--                        {{ value.name + (!last ? ', ' : '') }}-->
<!--                    </ng-container>-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div> -->
<!--        <div *ngIf="!exclude.includes('features') && featuredAttributes.length > 0" class="product-card__features">-->
            <ul *ngIf="!classProductCardLayoutTable">
                <li *ngFor="let attribute of product.attributes">
                    {{ attribute.name }}:
                    <ng-container *ngFor="let value of attribute.values; let last = last">
                        {{ value.name + (!last ? ', ' : '') }}
                    </ng-container>
                </li>
            </ul>
<!--        </div>-->
    </div>

    <div class="product-card__footer">
        <div *ngIf="(isAuth$|async)" class="product-card__prices">
            <ng-container *ngIf="product.stock !== 'out-of-stock' else noPrice">
                <ng-container *ngIf="product.compareAtPrice">
                    <div class="product-card__price product-card__price--new">{{ product.price|currencyFormat }}</div>
                    <div class="product-card__price product-card__price--old">{{ product.compareAtPrice|currencyFormat }}</div>
                </ng-container>
                <ng-container *ngIf="!product.compareAtPrice">
                    <div class="product-card__price product-card__price--current">{{ product.price|currencyFormat }}</div>
                </ng-container>
            </ng-container>
            <ng-template #noPrice>
                <div class="product-card__price product-card__price--current"></div>
            </ng-template>
        </div>
        <ng-container *ngIf="!exclude.includes('buttons') ">
            <button
                type="button"
                [ngStyle]="{'pointer-events': product.stock === 'in-stock' ? 'all' : 'none',
                'visibility': product.stock === 'in-stock' ? 'visible' : 'hidden'}"
                [class.i]="product.stock !== 'out-of-stock'"
                class="product-card__addtocart-icon"
                [class.product-card__addtocart-icon--loading]="cart.inProgress"
                [attr.aria-label]="'BUTTON_ADD_TO_CART'|translate"
                (click)="product.stock !== 'out-of-stock' && cart.add(product)"
            >
                <app-icon icon="cart-20"></app-icon>
            </button>
            <ng-container *ngIf="!exclude.includes('list-buttons')">
                <button
                    *ngIf="product.stock === 'in-stock'"
                    type="button"
                    class="product-card__addtocart-full"
                    [class.product-card__addtocart-full--loading]="cart.inProgress"
                    (click)="cart.add(product)"
                >
                    {{ 'BUTTON_ADD_TO_CART'|translate }}
                </button>
                <button
                    type="button"
                    class="product-card__wishlist"
                    [class.product-card__wishlist--loading]="wishlist.inProgress"
                    (click)="wishlist.add(product)"
                >
                    <app-icon icon="wishlist-16"></app-icon>
                    <span>{{ 'BUTTON_ADD_TO_WISHLIST'|translate }}</span>
                </button>
                <button
                    type="button"
                    class="product-card__compare"
                    [class.product-card__compare--loading]="compare.inProgress"
                    (click)="compare.add(product)"
                >
                    <app-icon icon="compare-16"></app-icon>
                    <span>{{ 'BUTTON_ADD_TO_COMPARE'|translate }}</span>
                </button>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<ng-template #nophoto>
    <img class="image__tag" [src]="'assets/images/placeholders/no-photo.png'" alt="no-photo">
</ng-template>
