<div class="post-card__image">
    <a routerLink="/blog/post-full-width">
        <img [src]="post.image" alt="">
    </a>
</div>
<div class="post-card__content">
    <div class="post-card__category">
        <a *ngFor="let category of post.categories" href="">{{ category }}</a>
    </div>
    <div class="post-card__title">
        <h2><a routerLink="/blog/post-full-width">{{ post.title }}</a></h2>
    </div>
    <div class="post-card__date">
        By <a href="">Jessica Moore</a> on {{ post.date }}
    </div>
    <div class="post-card__excerpt">
        <div class="typography">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis neque ut purus fermentum, ac pretium
            nibh facilisis. Vivamus venenatis viverra iaculis. Suspendisse tempor orci non sapien ullamcorper dapibus.
            Suspendisse at velit diam. Donec pharetra nec enim blandit vulputate.
        </div>
    </div>
    <div class="post-card__more">
        <a routerLink="/blog/post-full-width" class="btn btn-secondary btn-sm">Read more</a>
    </div>
</div>
