import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class HelperUtilsService {

    constructor(
        private translate: TranslateService,
    ) {
    }

    public sortByName(item: any, sort: string): any {
        return item.sort((a, b) => {
            if (['name_asc', 'name_desc'].includes(sort)) {
                if (a.name === b.name) {
                    return 0;
                }

                return (a.name > b.name ? 1 : -1) * (sort === 'name_asc' ? 1 : -1);
            }
        });
    }

    getTranslation(key: string): string {
        let translation: string;

        this.translate.get(key).subscribe((res: string) => {
            translation = res;
        });

        return translation;
    }
}
